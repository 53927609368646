import { useLocales } from '@sevenrooms/core/locales'
import { Switch, Route } from '@sevenrooms/core/navigation'
import { routes } from '@sevenrooms/core/routes'
import { ReservationStatus, PriorityAlert, BookReservation, ManageReservationContainer, WidgetContainer } from '../../components'
import { useVenue, useGetSevenroomsLogin } from '../../hooks'
import { reservationWidgetMessages } from '../../reservationWidgetMessages'

export function Alerts() {
  const { formatMessage } = useLocales()
  const { largeLogoUrl, urlKey, name } = useVenue()

  const sevenRoomsLogin = useGetSevenroomsLogin()

  return (
    <WidgetContainer
      largeLogoUrl={largeLogoUrl}
      urlKey={urlKey}
      venueName={name}
      enableGrowingTopNav={false}
      sevenRoomsLogin={sevenRoomsLogin}
    >
      <ManageReservationContainer>
        <Switch>
          <Route path={routes.explore.reservations.alerts.canceled.path}>
            <ReservationStatus header={formatMessage(reservationWidgetMessages.resCancelPageCancelPriorityAlertsSuccessHeader)}>
              <BookReservation />
            </ReservationStatus>
          </Route>
          <Route path={routes.explore.reservations.alerts.path}>
            <ReservationStatus header={formatMessage(reservationWidgetMessages.resCancelPageCancelPriorityAlertsHeader)}>
              <PriorityAlert />
            </ReservationStatus>
          </Route>
        </Switch>
      </ManageReservationContainer>
    </WidgetContainer>
  )
}
