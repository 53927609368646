import { skipToken } from '@reduxjs/toolkit/query'
import { useMemo } from 'react'
import type { ChargeType } from '@sevenrooms/core/api'
import {
  getShouldTimeSlotShowCost,
  type AvailabilityRangeRequest,
  type AvailabilityTime,
  type GuestFacingUpgrade,
} from '@sevenrooms/core/domain'
import { ReservationWidget } from '@sevenrooms/core/domain/constants'
import { TimeOnly } from '@sevenrooms/core/timepiece'
import { filterNullish } from '@sevenrooms/core/utils'
import { useGetSingleDayAvailabilityQuery, useGetRangeAvailabilityQuery, useGetGuestFacingUpgradeQuery } from '../store'
import { calculateCategoryFees, calculateReservationFees } from '../utils'
import { useWidgetSettings } from './useWidgetSettings'

// per duration costs are based on 15 minute intervals
const DURATION_15_MINUTES = 15

type WithRequired<T, K extends keyof T> = Omit<T, K> & Required<Pick<T, K>>

type RequiredAvailabilityRangeRequest = WithRequired<AvailabilityRangeRequest, 'partySize' | 'startDate' | 'timeSlot' | 'selectedLangCode'>

export type AvailabilityTimeWithUpSellCost = AvailabilityTime & {
  includedUpgradesCost: number
  showCost: boolean
  fees: number | undefined
}

export function useSingleDayAvailability({
  venueId,
  startTime,
  haloTimeIntervalMinutes,
  startOfDayTime,
  skipRequests,
  ...queryParams
}: Omit<RequiredAvailabilityRangeRequest, 'numDays'> & {
  venueId: string
  startTime: string
  haloTimeIntervalMinutes: number
  startOfDayTime: string
} & { skipRequests: boolean }) {
  const { isFetching: isAvailabilityFetching, data: availabilityData } = useGetSingleDayAvailabilityQuery(
    !skipRequests ? queryParams : skipToken,
    { refetchOnMountOrArgChange: true }
  )
  const { isFetching: isUpgradesFetching, data: upgradesData } = useGetGuestFacingUpgradeQuery(!skipRequests ? venueId : skipToken)
  const widgetSettings = useWidgetSettings()
  const data = useMemo(() => {
    if (!availabilityData || !upgradesData) {
      return undefined
    }
    const updatedAvailabilityTimes = availabilityData.availabilityTimes.map(timeSlot =>
      getTimeslotIncludedUpsellsCost(timeSlot, upgradesData, queryParams.partySize, widgetSettings.isFeesInPriceDisplayed)
    )
    return {
      ...availabilityData,
      availabilityTimes: updatedAvailabilityTimes,
    }
  }, [availabilityData, upgradesData, queryParams.partySize, widgetSettings.isFeesInPriceDisplayed])

  const filteredData = useMemo(() => {
    if (!data) {
      return data
    }
    const filteredAvailabilityTimes =
      startTime === ReservationWidget.AllTimesOption
        ? data.availabilityTimes
        : filterTimeSlotsByHaloTimeInterval(data.availabilityTimes, startTime, haloTimeIntervalMinutes, startOfDayTime)
    return {
      ...data,
      reservationTimesInHalo: getReservationTimesFromAvailability(filteredAvailabilityTimes),
      reservationTimes: getReservationTimesFromAvailability(data.availabilityTimes),
      requestTimeRange: getRequestableTimesFromAvailability({ availabilityTimes: filteredAvailabilityTimes }),
    }
  }, [data, haloTimeIntervalMinutes, startOfDayTime, startTime])

  const isClosed = useMemo(() => {
    if (!availabilityData) {
      return false
    }

    return availabilityData.shiftData.length === 0 || availabilityData.shiftData.every(shift => shift.isClosed)
  }, [availabilityData])

  return {
    isFetching: isAvailabilityFetching || isUpgradesFetching,
    isClosed,
    data: filteredData,
  }
}

export function useMultiDayAvailability({
  venueId,
  shouldSkip,
  startTime,
  haloTimeIntervalMinutes,
  startOfDayTime,
  ...queryParams
}: RequiredAvailabilityRangeRequest & {
  venueId: string
  shouldSkip: boolean
  startTime: string
  haloTimeIntervalMinutes: number
  startOfDayTime: string
}) {
  const { isFetching: isAvailabilityFetching, data: availabilityData } = useGetRangeAvailabilityQuery(shouldSkip ? skipToken : queryParams)
  const { isFetching: isUpgradesFetching, data: upgradesData } = useGetGuestFacingUpgradeQuery(shouldSkip ? skipToken : venueId)
  const widgetSettings = useWidgetSettings()
  const data = useMemo(() => {
    if (!availabilityData || !upgradesData) {
      return undefined
    }
    return Object.keys(availabilityData)
      .map(availabilityDate => {
        const specificAvailability = availabilityData[availabilityDate]
        if (!specificAvailability) {
          return undefined
        }
        const { shiftData } = specificAvailability
        const updatedReservationTimes = specificAvailability.availabilityTimes.map(timeSlot =>
          getTimeslotIncludedUpsellsCost(
            timeSlot,
            upgradesData,
            queryParams.partySize ?? widgetSettings.minGuests,
            widgetSettings.isFeesInPriceDisplayed
          )
        )
        return { date: availabilityDate, timeslots: getReservationTimesFromAvailability(updatedReservationTimes), shiftData }
      })
      .filter(filterNullish)
  }, [availabilityData, upgradesData, queryParams.partySize, widgetSettings.isFeesInPriceDisplayed, widgetSettings.minGuests])

  const filteredData = useMemo(() => {
    if (!data || startTime === ReservationWidget.AllTimesOption) {
      return data
    }
    return data.map(availability => {
      const filteredReservationTimes = filterTimeSlotsByHaloTimeInterval(
        availability.timeslots,
        startTime,
        haloTimeIntervalMinutes,
        startOfDayTime
      )
      return {
        ...availability,
        timeslots: filteredReservationTimes,
      }
    })
  }, [data, haloTimeIntervalMinutes, startOfDayTime, startTime])

  return {
    isFetching: isAvailabilityFetching || isUpgradesFetching,
    data: shouldSkip ? undefined : filteredData,
  }
}

export function getRequestableTimesFromAvailability({ availabilityTimes }: { availabilityTimes: AvailabilityTimeWithUpSellCost[] }) {
  return availabilityTimes
    .filter(({ type, isRequestable }) => type === 'request' && isRequestable !== false)
    .sort((availabilityTime1, availabilityTime2) => (availabilityTime1.sortOrder > availabilityTime2.sortOrder ? 1 : -1))
    .map(({ timeIso }) => timeIso)
    .reduce<string[]>((acc, value) => {
      if (!acc.includes(value)) {
        acc.push(value)
      }
      return acc
    }, [])
}

export function getReservationTimesFromAvailability(availabilityTimes: AvailabilityTimeWithUpSellCost[]) {
  return availabilityTimes.filter(({ type }) => type === 'book').sort((t1, t2) => t1.sortOrder - t2.sortOrder)
}

export function filterTimeSlotsByHaloTimeInterval(
  reservationTimes: AvailabilityTimeWithUpSellCost[],
  startTime: string,
  haloTimeIntervalMinutes: number,
  startOfDayTime: string
) {
  if (!reservationTimes.length) {
    return reservationTimes
  }

  const startOfDayTimeOnly = TimeOnly.from(startOfDayTime).toJsDate()
  const selectedTime = TimeOnly.from(startTime).toJsDate()
  const startTimeToCompare = new Date(selectedTime.getTime())
  startTimeToCompare.setMinutes(startTimeToCompare.getMinutes() - haloTimeIntervalMinutes)
  const endTimeToCompare = new Date(selectedTime.getTime())
  endTimeToCompare.setMinutes(endTimeToCompare.getMinutes() + haloTimeIntervalMinutes)
  const isNextDay = selectedTime.getTime() < startOfDayTimeOnly.getTime()

  return reservationTimes.filter(time => {
    const resTime = TimeOnly.from(time.timeIso).toJsDate()
    const isResTimeSameDay = isNextDay
      ? resTime.getTime() < startOfDayTimeOnly.getTime()
      : resTime.getTime() >= startOfDayTimeOnly.getTime()
    const isResTimeBelow = resTime.getTime() <= endTimeToCompare.getTime()
    const isResTimeAbove = resTime.getTime() >= startTimeToCompare.getTime()
    if (!isResTimeBelow && resTime.getDate() !== startTimeToCompare.getDate()) {
      resTime.setDate(resTime.getDate() - 1)
      return resTime.getTime() >= startTimeToCompare.getTime()
    } else if (!isResTimeAbove && resTime.getDate() !== endTimeToCompare.getDate()) {
      resTime.setDate(resTime.getDate() + 1)
      return resTime.getTime() <= endTimeToCompare.getTime()
    }
    return isResTimeAbove && isResTimeBelow && isResTimeSameDay
  })
}

export function getTimeslotIncludedUpsellsCost(
  timeSlot: AvailabilityTime,
  upgradesData: GuestFacingUpgrade,
  partySize: number,
  isFeesInPriceDisplayed = false
) {
  const showCost = getShouldTimeSlotShowCost(
    timeSlot.requireCreditCard,
    timeSlot.ccPartySizeMin,
    partySize,
    (timeSlot.selectedAutomaticUpsells?.length ?? 0) > 0
  )

  if (!showCost) {
    return {
      ...timeSlot,
      includedUpgradesCost: 0,
      fees: 0,
      showCost,
    }
  }

  const includedUpgradesCost =
    timeSlot.selectedAutomaticUpsells
      ?.map(({ id: upgradeId, quantityEqualType, quantityNum }) => {
        const upsellData = upgradesData.inventories.find(inventory => inventory.id === upgradeId)
        if (!upsellData) {
          return undefined
        }
        const quantity = quantityEqualType === 'SPECIFIC_NUMBER' ? quantityNum : partySize
        const { price } = upsellData
        const category = upgradesData.categories.find(category => category.id === upsellData.categoryId)
        const fees = category ? calculateCategoryFees(price, category, timeSlot.defaultServiceCharge, timeSlot.defaultGratuity) : 0
        return quantity * (price + (isFeesInPriceDisplayed ? fees : 0))
      })
      .reduce((acc = 0, value = 0) => value + acc, 0) ?? 0

  return {
    ...timeSlot,
    includedUpgradesCost: getCostFromChargeType(includedUpgradesCost, timeSlot.chargeType, partySize, timeSlot.duration),
    fees: calculateReservationFees(isFeesInPriceDisplayed, timeSlot),
    showCost,
  }
}

function getCostFromChargeType(cost: number, chargeType: ChargeType | null | undefined, partySize: number, duration: number) {
  if (cost === 0) {
    return 0
  }
  switch (chargeType) {
    case 'person_slot':
      return cost / partySize / (duration / DURATION_15_MINUTES)
    case 'person':
      return cost / partySize
    case 'reservation_slot':
      return cost / (duration / DURATION_15_MINUTES)
    case 'reservation':
    default:
      return cost
  }
}
