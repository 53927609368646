import { useCallback, useMemo } from 'react'
import type { LanguageCode } from '@sevenrooms/core/api'
import { useBeforeUnload } from '@sevenrooms/core/ui-kit/hooks'
import { Flex, Loader } from '@sevenrooms/core/ui-kit/layout'
import { PaymentProvider } from '@sevenrooms/payments'
import { WidgetContainer } from '../../components'
import {
  useAppLoading,
  useVenue,
  useWidgetLanguage,
  useWidgetSettings,
  useQueryParamAvailability,
  useModifyReservationRoute,
  useGetSevenroomsLogin,
} from '../../hooks'
import { updateFormState, useAppDispatch, useReservationFormState } from '../../store'
import { Checkout } from './Checkout'
import { useCheckoutTotals } from './useCheckoutTotals'

export function CheckoutPage() {
  const dispatch = useAppDispatch()
  const {
    id: venueId,
    largeLogoUrl,
    urlKey,
    name,
    paymentType,
    paymentAccountId,
    paymentStripePublicKey,
    paymentIsFreedompayHpcEnabled,
    paymentIsFreedompayHpcFrictionlessHandlingEnabled,
    paymentIsFreedompayBypass3DsEnabled,
  } = useVenue()
  const { isProd } = useWidgetSettings()
  const { availableLanguageOptions, selectedLanguage, setSelectedLanguage } = useWidgetLanguage()
  const isLoading = useAppLoading()
  const { hasQueryParamAvailability } = useQueryParamAvailability()
  const { formState } = useReservationFormState()
  const { isModifyRoute } = useModifyReservationRoute()
  const isResDetailsLoading = isModifyRoute && !formState.actual

  const handleSetSelectedLanguage = useCallback(
    (language: LanguageCode) => {
      setSelectedLanguage(language)
      dispatch(updateFormState({ preferredLanguage: language }))
    },
    [dispatch, setSelectedLanguage]
  )

  const languagePicker = useMemo(() => {
    if (availableLanguageOptions.length <= 1) {
      return undefined
    }
    return {
      value: selectedLanguage,
      onChange: handleSetSelectedLanguage,
      options: availableLanguageOptions,
      disabled: isLoading,
    }
  }, [availableLanguageOptions, handleSetSelectedLanguage, isLoading, selectedLanguage])

  const sevenRoomsLogin = useGetSevenroomsLogin()
  const { dueNowAmount } = useCheckoutTotals()

  useBeforeUnload(true)

  return (
    <WidgetContainer
      largeLogoUrl={largeLogoUrl}
      urlKey={urlKey}
      venueName={name}
      breadcrumbsPortalId="checkout-breadcrumbs"
      languagePicker={languagePicker}
      sevenRoomsLogin={sevenRoomsLogin}
    >
      {hasQueryParamAvailability || isResDetailsLoading ? (
        <Flex alignItems="center">
          <Loader />
        </Flex>
      ) : (
        <PaymentProvider
          value={{
            venueId,
            paymentType,
            amount: dueNowAmount,
            paymentSettings: {
              paymentAccountId,
              paymentStripePublicKey,
              selectedLanguage,
              paymentIsFreedompayHpcEnabled,
              paymentIsFreedompayHpcFrictionlessHandlingEnabled,
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              paymentIsFreedompayBypass3DsEnabled,
              isProd,
            },
          }}
        >
          <Checkout />
        </PaymentProvider>
      )}
    </WidgetContainer>
  )
}

export * from './useCheckoutForm'
