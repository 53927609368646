import { Route, Switch, useLocation, useScrollToTop, Redirect, generatePath } from '@sevenrooms/core/navigation'
import { routes } from '@sevenrooms/core/routes'
import { Window } from '@sevenrooms/core/ui-kit/layout'
import { ErrorModal } from '../components'
import { useModals } from '../store'
import { Alerts } from './Alerts'
import { Create } from './Create'
import { ManageReservation } from './Manage'
import { Modify } from './Modify'
import { Prearrival } from './Prearrival'

const reservationsPath = routes.explore.reservations

export function Reservations() {
  const { pathname } = useLocation()
  useScrollToTop(pathname)

  const { isErrorModalActive, errorMessage, errorTitle, showTryAgainBtn, hideErrorModal } = useModals()

  return (
    <>
      <Switch>
        <Route path={reservationsPath.create.path}>
          <Create />
        </Route>
        <Route path={reservationsPath.modify.path}>
          <Modify />
        </Route>
        <Route path={reservationsPath.prearrival.path}>
          <Prearrival />
        </Route>
        <Route path={reservationsPath.alerts.path}>
          <Alerts />
        </Route>
        <Route path={reservationsPath.manage.path}>
          <ManageReservation />
        </Route>
        <Route
          exact
          path={reservationsPath.search.path}
          render={({ location, match }) => {
            const pathname = generatePath(reservationsPath.create.search.path, match.params)
            return <Redirect to={{ pathname, search: location.search }} />
          }}
        />
        <Redirect to={reservationsPath.create.search.path} />
      </Switch>
      <Window active={isErrorModalActive}>
        <ErrorModal
          errorMessage={errorMessage}
          errorTitle={errorTitle}
          showTryAgainBtn={showTryAgainBtn}
          onClose={() => hideErrorModal()}
        />
      </Window>
    </>
  )
}
