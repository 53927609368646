import camelcaseKeys from 'camelcase-keys'
import { type ReservationWidget, ReservationWidgetAdapter } from '@sevenrooms/core/api'

declare const window: ReservationWidget.Window

export const preloadedState = {
  app: ReservationWidgetAdapter.appToClient(window.PRELOADED),
  analyticsSettings: camelcaseKeys(window.PRELOADED.analytics_settings, { deep: true }),
  privateEventsSettings: camelcaseKeys(window.PRELOADED.private_events_settings, { deep: true }),
  salutationOptions: window.PRELOADED.salutation_options,
  venueInfo: ReservationWidgetAdapter.venueToClient(window.PRELOADED.base_venue),
  clientInfo: ReservationWidgetAdapter.clientInfoToClient(window.PRELOADED.client_info),
  widgetSettings: ReservationWidgetAdapter.settingsToClient(window.PRELOADED.widget_settings),
  selectedLanguageStrings: ReservationWidgetAdapter.preloadedStringsToClient(window.PRELOADED.selected_language_strings),
  templateData: ReservationWidgetAdapter.templateToClient(window.PRELOADED.template_data),
}

export type PreloadedState = typeof preloadedState
