import { useLocales } from '@sevenrooms/core/locales'
import { DateOnly } from '@sevenrooms/core/timepiece'
import { GFL_ALLOWED_TAGS, HTMLContent } from '@sevenrooms/core/ui-kit/core'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { VStack, Window } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { useLanguageStrings, useVenue, useWidgetSettings } from '../../hooks'
import { CreatePriorityAlert } from '../../Reservations/Search/PriorityAlert/CreatePriorityAlert'
import { CreatePriorityAlertSuccessful } from '../../Reservations/Search/PriorityAlert/CreatePriorityAlertSuccessful'
import { CreateRequest } from '../../Reservations/Search/Request/CreateRequest'
import { reservationWidgetMessages } from '../../reservationWidgetMessages'
import { useModals } from '../../store'
import { WidgetTitle } from '../WidgetTitle'
import { AlertMeButton } from './AlertMeButton'
import { ReservationAvailability, type ReservationAvailabilityProps } from './ReservationAvailability'
import { SpecialAttention } from './SpecialAttention'
import { SubmitARequestButton } from './SubmitARequestButton'

export interface PrimaryAvailabilityProps {
  startDate: string
  partySize: number
  onClick: ReservationAvailabilityProps['onClick']
  venueCurrencyCode: string
  onClearTimeFilter: () => void
  isDayBlocked: (day: Date) => boolean
  reservationTimesInHalo: ReservationAvailabilityProps['timeslots']
  requestTimeRange: string[]
  reservationTimes: ReservationAvailabilityProps['timeslots']
  isAvailabilityFetching: boolean
  isClosed: boolean
}

export function PrimaryAvailability({
  startDate,
  partySize,
  onClick,
  venueCurrencyCode,
  onClearTimeFilter,
  isDayBlocked,
  reservationTimesInHalo,
  requestTimeRange,
  reservationTimes,
  isAvailabilityFetching,
  isClosed,
}: PrimaryAvailabilityProps) {
  const { formatMessage } = useLocales()

  const { isFetching: isFetchingLanguage } = useLanguageStrings()
  const timesOutSideOfHalo = reservationTimes.length - reservationTimesInHalo.length

  const noAvailabilityTitle = () => {
    if (isDayBlocked(DateOnly.from(startDate).toJsDate())) {
      return formatMessage(reservationWidgetMessages.resWidgetBlackoutDate)
    }

    if (isClosed) {
      return formatMessage(reservationWidgetMessages.resWidgetNoShiftsOnDate, {
        date: DateOnly.from(startDate).formatSMonthNDaySWeek(),
      })
    }

    return (
      <>
        <HTMLContent allowedTags={GFL_ALLOWED_TAGS} content={formatMessage(reservationWidgetMessages.resWidgetNoAvailabilityCriteria)} />
        {reservationTimesInHalo.length === 0 && reservationTimes.length ? (
          <Button data-test="sr-show-x-more-times" type="button" variant="tertiary" noPadding onClick={onClearTimeFilter}>
            <Text textStyle="h3" color="primaryFont" textDecoration="underline">
              {formatMessage(reservationWidgetMessages.resWidgetShowAllTimes, {
                date: DateOnly.from(startDate).formatSMonthNDaySWeek(),
              })}
            </Text>
          </Button>
        ) : null}
      </>
    )
  }

  const { showSpecialAttentionMessage } = useWidgetSettings()
  return (
    <VStack width="100%" pt="lm" pb="lm" spacing="lm">
      <WidgetTitle />
      {showSpecialAttentionMessage && <SpecialAttention data-test="venue-special-attention" />}
      <ReservationAvailability
        isLoadingData={isAvailabilityFetching}
        isLoadingLanguage={isFetchingLanguage}
        onClick={onClick}
        timeslots={reservationTimesInHalo}
        variant="primary"
        noAvailabilityTitle={noAvailabilityTitle()}
        currencyCode={venueCurrencyCode}
        requestOrPriorityAlertAvailability={
          <RequestOrPriorityAlertAvailability
            requestTimeSlots={requestTimeRange}
            isAvailabilityEmpty={reservationTimesInHalo.length === 0}
            partySize={partySize}
          />
        }
      />
      {!!timesOutSideOfHalo && reservationTimesInHalo.length !== 0 && (
        <div>
          <Button data-test="sr-show-x-more-times" type="button" variant="tertiary" noPadding onClick={onClearTimeFilter}>
            <Text color="primaryFont" textDecoration="underline">
              {formatMessage(reservationWidgetMessages.resWidgetShowXMoreTimes, {
                number_of_times: timesOutSideOfHalo,
                date: DateOnly.from(startDate).formatSMonthNDaySWeek(),
              })}
            </Text>
          </Button>
        </div>
      )}
    </VStack>
  )
}

export function RequestOrPriorityAlertAvailability({
  isAvailabilityEmpty,
  partySize,
  requestTimeSlots,
}: {
  requestTimeSlots: string[]
  isAvailabilityEmpty: boolean
  partySize: number
}) {
  const venue = useVenue()
  const widgetSettings = useWidgetSettings()
  const variant = isAvailabilityEmpty ? 'primary' : 'secondary'
  const { showModal, hideModal, activeModal } = useModals()
  const showPriorityAlertButton =
    !!requestTimeSlots.length &&
    venue.priorityAlertsEnabled &&
    venue.priorityAlertsPartySizeMin != null &&
    venue.priorityAlertsPartySizeMax != null &&
    partySize >= venue.priorityAlertsPartySizeMin &&
    partySize <= venue.priorityAlertsPartySizeMax

  const showRequestButton = !showPriorityAlertButton && widgetSettings.enableRequests && !!requestTimeSlots.length

  return (
    <>
      {activeModal === 'createPriorityAlert' && (
        <Window active>
          <CreatePriorityAlert validRequestTimes={requestTimeSlots} onClose={hideModal} />
        </Window>
      )}
      {activeModal === 'createRequest' && (
        <Window active>
          <CreateRequest validRequestTimes={requestTimeSlots} onClose={hideModal} />
        </Window>
      )}
      {activeModal === 'createPriorityAlertSuccess' && (
        <Window active>
          <CreatePriorityAlertSuccessful isPriorityAlert onClose={hideModal} />
        </Window>
      )}
      {activeModal === 'createRequestSuccess' && (
        <Window active>
          <CreatePriorityAlertSuccessful isPriorityAlert={false} onClose={hideModal} />
        </Window>
      )}
      {showRequestButton && (
        <SubmitARequestButton
          onClick={() => showModal('createRequest')}
          size={variant === 'primary' ? 'm' : 'l'}
          variant={variant}
          width={variant === 'primary' ? '240px' : '100%'}
        />
      )}
      {showPriorityAlertButton && (
        <AlertMeButton
          onClick={() => showModal('createPriorityAlert')}
          size={variant === 'primary' ? 'm' : 'l'}
          variant={variant}
          width={variant === 'primary' ? '240px' : '100%'}
        />
      )}
    </>
  )
}
