import { useMemo } from 'react'
import { generatePath, useNavigation } from '@sevenrooms/core/navigation'
import { routes } from '@sevenrooms/core/routes'

export const useManageReservationRoute = () => {
  const { matchQuery, matchParams } = useNavigation()
  const { venueKey } = matchParams(routes.explore) ?? {}
  const {
    lang = 'en',
    token,
    is_confirm: isConfirm,
    is_success: isSuccess,
    is_success_edit: isSuccessEdit,
  } = matchQuery(routes.explore.reservations.manage) ?? {}
  const linkToModifyReservation = useMemo(() => generatePath(routes.direct.modifyReservation.path, { token }, { lang }), [token, lang])
  const linkToPurchaseUpgrades = useMemo(
    () => generatePath(routes.direct.modifyReservation.path, { token }, { lang, source: 'prearrival' }),
    [token, lang]
  )

  return {
    token,
    isConfirm: isConfirm === 'true',
    isSuccess: isSuccess === 'true',
    isSuccessEdit: isSuccessEdit === 'true',
    lang,
    venueKey,
    linkToModifyReservation,
    linkToPurchaseUpgrades,
  }
}
