import { useCallback, useMemo } from 'react'
import { matchPath, useLocation, useNavigation } from '@sevenrooms/core/navigation'
import { routes } from '@sevenrooms/core/routes'
import { useModifyReservationRoute } from './useModifyReservationRoute'

type ReservationQueryParamKeys = keyof typeof routes.explore.reservations.queryType

const PAYMENT_ERROR = 'failed_payment'

export const useReservationsRoute = () => {
  const { matchQuery, updateQuery } = useNavigation()
  const { pathname } = useLocation()
  const { isModifyRoute } = useModifyReservationRoute()

  const reservationRouteParams = useMemo(
    () => matchQuery(isModifyRoute ? routes.explore.reservations.modify : routes.explore.reservations),
    [isModifyRoute, matchQuery]
  )
  const {
    venues,
    timeslot_id: timeslotId,
    timeslot_time: timeslotTime,
    primary_venue_id: primaryVenueId,
    client_id: clientId,
    request_id: requestId,
    instant_experiences_enabled: instantExperiencesEnabled,
    error_display: hasError,
    error_message: errorMessage,
    searchTab,
  } = reservationRouteParams ?? {}

  const { isManageRoute, isSearchRoute } = useMemo(
    () => ({
      isManageRoute:
        !!matchPath(pathname, { path: routes.explore.reservations.manage.path }) ||
        !!matchPath(pathname, { path: routes.explore.reservations.alerts.path }),
      isSearchRoute:
        !!matchPath(pathname, { path: routes.explore.reservations.create.search.path }) ||
        !!matchPath(pathname, { path: routes.explore.reservations.modify.search.path }),
    }),
    [pathname]
  )

  const updateReservationQuery = useCallback(
    ({
      partySize,
      date,
      time,
      halo,
      venues,
      timeslotId,
      timeslotTime,
      language,
      clientId,
      searchTab,
    }: {
      partySize?: string
      date?: string
      time?: string
      halo?: string
      venues?: string
      timeslotId?: string
      timeslotTime?: string
      language?: string
      clientId?: string
      searchTab?: string
    }) => {
      updateQuery(
        routes.explore.reservations,
        {
          // Be careful. reservationRouteParams variable can have old param values. Should be fixed.
          ...reservationRouteParams,
          party_size: partySize,
          date,
          start_time: time,
          halo,
          venues,
          timeslot_id: timeslotId,
          timeslot_time: timeslotTime,
          lang: language,
          client_id: clientId,
          searchTab,
        },
        'replaceIn'
      )
    },
    [reservationRouteParams, updateQuery]
  )

  const clearQueryParams = useCallback(
    (...params: ReservationQueryParamKeys[]) => {
      if (params.length === 0) {
        return
      }

      // Cannot use reservationRouteParams variable because it provides old param values, not the latest value.
      // Using values from window.location.search instead as it is the source of truth.
      const updatedQueryParams: { [key: string]: string } = {}
      const searchParams = new URLSearchParams(window.location.search)
      searchParams.forEach((value, key) => {
        if (!params.includes(key as ReservationQueryParamKeys)) {
          updatedQueryParams[key] = value
        }
      })
      updateQuery(isModifyRoute ? routes.explore.reservations.modify : routes.explore.reservations, updatedQueryParams, 'replace')
    },
    [isModifyRoute, updateQuery]
  )

  return {
    venues,
    timeslotId,
    timeslotTime,
    primaryVenueId,
    isManageRoute,
    isSearchRoute,
    updateQuery: updateReservationQuery,
    clearQueryParams,
    clientId,
    requestId,
    instantExperiencesEnabled: instantExperiencesEnabled === 'true',
    hasPaymentError: hasError === 'true' && errorMessage === PAYMENT_ERROR,
    searchTab,
  }
}
