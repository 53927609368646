import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import type { RootState, AppDispatch } from '../store'

export type VisibleModals =
  | 'filterTime'
  | 'createRequest'
  | 'createPriorityAlert'
  | 'createRequestSuccess'
  | 'createPriorityAlertSuccess'
  | 'reservationAvailability'
  | 'confirmOtherVenueNavigation'

interface ErrorModalState {
  activeModal: VisibleModals | undefined
  isErrorModalActive: boolean
  errorMessage: string | undefined
  errorTitle: string | undefined
  showTryAgainBtn: boolean | undefined
}

const initialState: ErrorModalState = {
  activeModal: undefined,
  isErrorModalActive: false,
  errorMessage: undefined,
  errorTitle: undefined,
  showTryAgainBtn: undefined,
}

export const modalsSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    showModal: (state, action: PayloadAction<{ modal: NonNullable<VisibleModals> }>) => {
      const {
        payload: { modal: activeModal },
      } = action
      state.activeModal = activeModal
    },
    hideModal: state => {
      state.activeModal = undefined
    },
    showErrorModal: (state, action: PayloadAction<{ errorMessage?: string; errorTitle?: string; showTryAgainBtn?: boolean }>) => {
      state.isErrorModalActive = true
      state.errorMessage = action.payload.errorMessage
      state.errorTitle = action.payload.errorTitle
      state.showTryAgainBtn = action.payload.showTryAgainBtn
    },
    hideErrorModal: (state, action: PayloadAction<{ keepMessage: boolean } | undefined>) => {
      state.isErrorModalActive = false
      if (action.payload?.keepMessage !== true) {
        state.errorMessage = undefined
        state.errorTitle = undefined
        state.showTryAgainBtn = undefined
      }
    },
    resetModalsState: state => {
      state.activeModal = undefined
      state.errorMessage = undefined
      state.isErrorModalActive = false
    },
  },
})

export const { showModal, hideModal, showErrorModal, hideErrorModal, resetModalsState } = modalsSlice.actions

export const useModals = () => {
  const dispatch = useDispatch<AppDispatch>()
  const { activeModal, errorMessage, isErrorModalActive, errorTitle, showTryAgainBtn } = useSelector((state: RootState) => state.modals)

  const showModalCallback = useCallback(
    (modal: VisibleModals) => {
      dispatch(showModal({ modal }))
    },
    [dispatch]
  )

  const hideModalCallback = useCallback(() => {
    dispatch(hideModal())
  }, [dispatch])

  const showErrorModalCallback = useCallback(
    (errorMessage?: string, errorTitle?: string, showTryAgainBtn?: boolean) => {
      dispatch(showErrorModal({ errorMessage, errorTitle, showTryAgainBtn }))
    },
    [dispatch]
  )

  const hideErrorModalCallback = useCallback(
    (keepMessage?: boolean) => {
      dispatch(hideErrorModal({ keepMessage: keepMessage ?? false }))
    },
    [dispatch]
  )

  const resetModalsStateCallback = useCallback(() => {
    dispatch(resetModalsState)
  }, [dispatch])

  return {
    activeModal,
    errorMessage,
    errorTitle,
    showTryAgainBtn,
    isErrorModalActive,
    showModal: showModalCallback,
    hideModal: hideModalCallback,
    showErrorModal: showErrorModalCallback,
    hideErrorModal: hideErrorModalCallback,
    resetModalsState: resetModalsStateCallback,
  }
}

export const modalsReducer = modalsSlice.reducer
