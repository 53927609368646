import { skipToken } from '@reduxjs/toolkit/query'
import { useCallback, useMemo } from 'react'
import type { ManageReservationCancelResult } from '@sevenrooms/core/domain'
import { useNavigation } from '@sevenrooms/core/navigation'
import { routes } from '@sevenrooms/core/routes'
import { HTMLContent } from '@sevenrooms/core/ui-kit/core'
import type { ModalHeaderProps } from '@sevenrooms/core/ui-kit/layout'
import { useVenue, useManageReservationRoute } from '../../../hooks'
import { useCancelReservationMutation, useGetManageReservationDetailsQuery, useModals } from '../../../store'
import { BaseCancelModal } from '../BaseCancelModal'
import { CancellationFeeModal } from '../CancellationFeeModal'
import { RefundModal } from '../RefundModal'
import { UnableCancelModal } from '../UnableCancelModal'

interface CancelSurfaceProps {
  closeHref: NonNullable<ModalHeaderProps['closeHref']>
}

export function CancelModal({ closeHref }: CancelSurfaceProps) {
  const { showErrorModal } = useModals()
  const { closeSurface, push } = useNavigation()
  const venue = useVenue()
  const { lang, token } = useManageReservationRoute()

  const { data, isLoading } = useGetManageReservationDetailsQuery(token ? { lang, token } : skipToken)
  const [cancelReservation, { isLoading: isCanceling }] = useCancelReservationMutation()
  const { policies, actualFlags, cancelDetails, refundDetails } = data ?? {}
  const cancellationPolicy = useMemo(
    () => (policies?.cancellationPolicy ? <HTMLContent content={policies?.cancellationPolicy} allowedTags={false} /> : null),
    [policies?.cancellationPolicy]
  )

  const onCancelClick = useCallback(async () => {
    try {
      const result: ManageReservationCancelResult = await cancelReservation({ token: token as string, lang }).unwrap()
      if (result.actualFlags.hasPassed) {
        closeSurface(closeHref)
        push(routes.explore.reservations.manage.passed, {
          params: { venueKey: venue.urlKey },
        })
      } else if (result.isCanceled) {
        closeSurface(closeHref)
        push(routes.explore.reservations.manage.canceled, {
          params: { venueKey: venue.urlKey },
        })
      }
    } catch {
      showErrorModal()
    }
  }, [cancelReservation, token, lang, closeSurface, closeHref, push, venue.urlKey, showErrorModal])

  if (isLoading) {
    return null
  }

  if (!actualFlags?.isCancelable) {
    return <UnableCancelModal closeHref={closeHref} />
  }

  if (cancelDetails) {
    return (
      <CancellationFeeModal
        amount={cancelDetails.cancellationChargeAmountDecimal}
        cardBrand={cancelDetails.cancellationCardInfo.brand}
        cardLast4={cancelDetails.cancellationCardInfo.last4}
        cancellationPolicy={cancellationPolicy}
        onCancelClick={onCancelClick}
        isCancelDisabled={isCanceling}
        closeHref={closeHref}
      />
    )
  }

  if (refundDetails) {
    return (
      <RefundModal
        charges={refundDetails.refundableCharges}
        cancellationPolicy={cancellationPolicy}
        isCancelDisabled={isCanceling}
        onCancelClick={onCancelClick}
        closeHref={closeHref}
      />
    )
  }

  return (
    <BaseCancelModal
      cancellationPolicy={cancellationPolicy}
      closeHref={closeHref}
      isCancelDisabled={isCanceling}
      onCancelClick={onCancelClick}
    />
  )
}
