import { skipToken } from '@reduxjs/toolkit/query'
import { useEffect, useMemo, useState } from 'react'
import type { LanguageCode } from '@sevenrooms/core/api'
import type { ReservationWidget } from '@sevenrooms/core/domain'
import usEnglishTranslationStrings from '../lang/compiled/en-US.json'
import { useAppSelector, useGetLanguageStringsQuery } from '../store'
import { useVenue } from './useVenue'
import { useWidgetLanguage } from './useWidgetLanguage'

class LanguageStrings {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private cache: { [key: string]: any }

  constructor() {
    this.cache = {}
  }

  async getTranslations(language: LanguageCode) {
    if (this.cache[language]) {
      return this.cache[language]
    }

    let loadedLanguageStrings

    // Webpack does not like dynamically generated paths. So, explicit imports are needed.
    switch (language) {
      case 'da':
        loadedLanguageStrings = await import('../lang/compiled/da-DK.json')
        break
      case 'de':
        loadedLanguageStrings = await import('../lang/compiled/de-DE.json')
        break
      case 'en':
        // US languages are already loaded by default
        loadedLanguageStrings = {}
        break
      case 'es':
        loadedLanguageStrings = await import('../lang/compiled/es-ES.json')
        break
      case 'fr':
        loadedLanguageStrings = await import('../lang/compiled/fr-FR.json')
        break
      case 'hu':
        loadedLanguageStrings = await import('../lang/compiled/hu-HU.json')
        break
      case 'it':
        loadedLanguageStrings = await import('../lang/compiled/it-IT.json')
        break
      case 'ja':
        loadedLanguageStrings = await import('../lang/compiled/ja-JP.json')
        break
      case 'nl':
        loadedLanguageStrings = await import('../lang/compiled/nl-NL.json')
        break
      case 'pt':
        loadedLanguageStrings = await import('../lang/compiled/pt-BR.json')
        break
      case 'sv':
        loadedLanguageStrings = await import('../lang/compiled/sv-SE.json')
        break
      case 'th':
        loadedLanguageStrings = await import('../lang/compiled/th-TH.json')
        break
      case 'tr':
        loadedLanguageStrings = await import('../lang/compiled/tr-TR.json')
        break
      case 'zh_CN':
        loadedLanguageStrings = await import('../lang/compiled/zh-CN.json')
        break
      case 'zh_HK':
        loadedLanguageStrings = await import('../lang/compiled/zh-HK.json')
        break
      default:
        // eslint-disable-next-line no-console
        console.error('Invalid language code: Language file cannot be loaded.')
        return {}
    }

    this.cache[language] = loadedLanguageStrings.default
    return this.cache[language]
  }
}

const languageStrings = new LanguageStrings()

export function useLanguageStrings(skipLoading = false) {
  const { urlKey } = useVenue()
  const { selectedLanguage } = useWidgetLanguage()
  const preloadedStrings = useAppSelector(state => state.selectedLanguageStrings)
  const { isError, isFetching, data } = useGetLanguageStringsQuery(
    skipLoading
      ? skipToken
      : {
          venueUrlKey: urlKey,
          languageCode: selectedLanguage,
          widgetType: 'res_widget',
        }
  )
  const [loadedTranslationStrings, setLoadedTranslationStrings] = useState({})

  useEffect(() => {
    async function loadTranslationStrings(language: LanguageCode) {
      const loadedLanguageStrings = await languageStrings.getTranslations(language)
      setLoadedTranslationStrings(loadedLanguageStrings)
    }

    loadTranslationStrings(selectedLanguage)
  }, [selectedLanguage])

  const messages: ReservationWidget.PreloadedLanguageStrings | ReservationWidget.LanguageMessages = useMemo(
    () =>
      data?.languageStrings
        ? {
            ...usEnglishTranslationStrings,
            ...loadedTranslationStrings,
            ...preloadedStrings,
            ...data.languageStrings,
          }
        : {
            ...usEnglishTranslationStrings,
            ...loadedTranslationStrings,
            ...preloadedStrings,
          },
    [data, loadedTranslationStrings, preloadedStrings]
  )

  return {
    messages,
    isFetching,
    isLanguageMessages: (
      messages: ReservationWidget.PreloadedLanguageStrings | ReservationWidget.LanguageMessages
    ): messages is ReservationWidget.LanguageMessages => !isFetching && !skipLoading,
    isError,
  }
}
