import { useRef, useEffect, useMemo } from 'react'
import { ReservationWidget } from '@sevenrooms/core/domain/constants'
import { legacyJSDateAdapter, DateOnly, parseISO, Formats } from '@sevenrooms/core/timepiece'
import { useReservationFormState } from '../store'
import { useSingleDayAvailability } from './useAvailability'
import { useCachedCreateReservationHold } from './useCachedCreateReservationHold'
import { useReservationNavigation } from './useReservationNavigation'
import { useReservationsRoute } from './useReservationsRoute'
import { useVenue } from './useVenue'
import { useWidgetSettings } from './useWidgetSettings'

export function useQueryParamAvailability() {
  const { timeslotId, timeslotTime } = useReservationsRoute()
  const {
    formState: { selectedTimeSlot, startDate, partySize, startTime, preferredLanguage, trackingSlug, clientId },
    updateFormState,
  } = useReservationFormState()
  const { navigateToFirstStep, currentStep } = useReservationNavigation()
  const formUpdatedRef = useRef(false)
  const { id: venueId, urlKey, startOfDayTime } = useVenue()
  const { reservationHoldEnabled } = useWidgetSettings()
  const hasQueryParamAvailability =
    !!timeslotTime && !!timeslotId && timeslotTime !== selectedTimeSlot?.accessPersistentId && timeslotTime !== selectedTimeSlot?.time
  const [createReservationHold] = useCachedCreateReservationHold()
  const { data: availabilityData } = useSingleDayAvailability({
    venueId,
    venue: urlKey,
    startDate: DateOnly.from(startDate).toIso(),
    startTime,
    partySize,
    selectedLangCode: preferredLanguage,
    haloTimeIntervalMinutes: 120,
    haloSizeInterval: 5,
    timeSlot: timeslotTime ?? '',
    channel: ReservationWidget.SevenRoomsWidgetChannel,
    startOfDayTime,
    skipRequests: !hasQueryParamAvailability || formUpdatedRef.current,
    clientId,
  })

  const queryParamAvailability = useMemo(() => {
    if (!availabilityData || !timeslotId || !timeslotTime) {
      return undefined
    }
    return (
      availabilityData.reservationTimes.find(
        availability => availability.accessPersistentId === timeslotId && availability.time === timeslotTime
      ) ?? 'invalid'
    )
  }, [timeslotId, timeslotTime, availabilityData])

  useEffect(() => {
    if (formUpdatedRef.current || !hasQueryParamAvailability || !queryParamAvailability) {
      return
    }
    formUpdatedRef.current = true
    const isNavigationProhibited =
      queryParamAvailability === 'invalid' || (currentStep?.name === 'checkout' && !!queryParamAvailability?.upsellCategories?.length)
    if (isNavigationProhibited) {
      navigateToFirstStep()
      return
    }
    updateFormState({ selectedTimeSlot: queryParamAvailability })
    if (reservationHoldEnabled) {
      createReservationHold({
        accessPersistentId: queryParamAvailability.accessPersistentId,
        channel: 'SEVENROOMS_WIDGET',
        date: legacyJSDateAdapter(parseISO(queryParamAvailability.timeIso), Formats.MonthDayYearByDash),
        partySize,
        shiftPersistentId: queryParamAvailability.shiftPersistentId,
        time: queryParamAvailability.time,
        venueId,
        trackingSlug,
        clientId,
      })
    }
  }, [
    hasQueryParamAvailability,
    createReservationHold,
    navigateToFirstStep,
    queryParamAvailability,
    partySize,
    reservationHoldEnabled,
    trackingSlug,
    updateFormState,
    venueId,
    currentStep?.name,
    clientId,
  ])

  return {
    hasQueryParamAvailability,
  }
}
