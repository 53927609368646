import moment from 'moment/moment'
import { type PropsWithChildren, useMemo, useEffect } from 'react'
import { Provider } from 'react-redux'
import { issueMessages } from '@sevenrooms/core/form'
import { CurrencyLocale, Locale } from '@sevenrooms/core/locales'
import { Route, Router, Switch } from '@sevenrooms/core/navigation'
import { routes } from '@sevenrooms/core/routes'
import { TimeLocale } from '@sevenrooms/core/timepiece'
import { facebookButtonMessages, promoCodeMessages } from '@sevenrooms/core/ui-kit/core'
import { formTimeRangePickerMessages } from '@sevenrooms/core/ui-kit/form'
import { Root, logoFooterMessages } from '@sevenrooms/core/ui-kit/layout'
import { guestPreferencesMessages } from './components'
import { reservationAvailabilityMessages } from './components/PrimaryAvailability/ReservationAvailability'
import { reservationDetailsMessages } from './components/ReservationDetailCard'
import { priorityAlertDetailsMessages } from './components/ReservationStatus/PriorityAlertDetails'
import { venueDetailsMessages } from './components/VenueDetails'
import { useVenue, useLanguageStrings, useWidgetSettings, useReservationsRoute, useWidgetLanguage, useAnalyticsSettings } from './hooks'
import { Reservations } from './Reservations'
import { additionalUpgradesCardMessages } from './Reservations/Manage/AdditionalUpgradesCard'
import { paymentDetailsMessages } from './Reservations/Manage/PaymentDetails'
import { paymentHistoryMessages } from './Reservations/Manage/PaymentDetails/PaymentHistory'
import { policyDetailsMessages } from './Reservations/Manage/PolicyDetails'
import { upgradeDetailsMessages } from './Reservations/Manage/UpgradeDetails'
import { upgradesBannerMessages } from './Reservations/Manage/UpgradesBanner'
import { reservationAvailabilityModalMessages } from './Reservations/Search/ReservationAvailabilityModal'
import { store } from './store'
import { widgetLoadStarted } from './utils'

function AppRoot({ children }: PropsWithChildren<{}>) {
  const widgetSettings = useWidgetSettings()
  const {
    analyticsEventMapping: { pageView },
  } = useAnalyticsSettings()
  const { locale, venueColor, urlKey: venueUrlKey } = useVenue()
  const { isManageRoute } = useReservationsRoute()
  const { selectedLanguage } = useWidgetLanguage()
  const venueLocale = Locale.getLocale(locale)
  const venueSensitiveLocale = Locale.getVenueSensitiveLocale(venueLocale, selectedLanguage)
  const { messages: widgetMessages, isLanguageMessages } = useLanguageStrings(isManageRoute)

  useEffect(() => {
    CurrencyLocale.setLocale(venueLocale)
    TimeLocale.setLocale(venueSensitiveLocale)
    TimeLocale.setDefaultFormatOptions({ hour12: venueLocale?.toLowerCase() !== 'en-gb' })
    moment.locale(venueSensitiveLocale)
    widgetLoadStarted(venueUrlKey, pageView)
  }, [venueSensitiveLocale, venueLocale, venueUrlKey, pageView])

  const messages = useMemo(
    () => ({
      ...widgetMessages,
      [paymentDetailsMessages.title.id]: widgetMessages.commonPaymentDetailsText,
      [paymentDetailsMessages.total.id]: widgetMessages.commonTotalText,
      [upgradeDetailsMessages.title.id]: widgetMessages.commonUpgradesLabel,
      [venueDetailsMessages.getDirections.id]: widgetMessages.commonGetDirectionsLink,
      [policyDetailsMessages.bookingCheckoutPolicyHeader.id]: widgetMessages.resConfEmailHeader,
      [policyDetailsMessages.specialAttentionMessageHeader.id]: widgetMessages.resWidgetSpecialAttentionLabel,
      [policyDetailsMessages.specialAttentionMessageBody.id]: widgetMessages.resWidgetSpecialAttentionInfoBody,
      [reservationDetailsMessages.partySize.id]: widgetMessages.resCancelPagePartySize,
      [reservationDetailsMessages.tableMinimum.id]: widgetMessages.resCancelPageTableMinText,
      [reservationDetailsMessages.reservedFor.id]: widgetMessages.commonReservedForText,
      [reservationDetailsMessages.reservationNumber.id]: widgetMessages.resCancelPageReservationId,
      [guestPreferencesMessages.guestPreferencesSave.id]: widgetMessages.resWidgetSaveButtonLabel,
      [guestPreferencesMessages.guestDietaryRestriction.id]: widgetMessages.resConfirmPageDietaryRestrictQuestion,
      [guestPreferencesMessages.guestImageHeader.id]: widgetMessages.resConfirmPageProfilePhotoDesc,
      [guestPreferencesMessages.guestImageUploadButton.id]: widgetMessages.resConfirmPageUploadPhotoButton,
      [guestPreferencesMessages.guestImageDeleteButton.id]: widgetMessages.resConfirmPageRemovePhotoButton,
      [guestPreferencesMessages.guestOtherInfo.id]: widgetMessages.resConfirmPageOtherInfoLabel,
      [guestPreferencesMessages.guestPreferencesHeader.id]: widgetMessages.resConfirmPageDesc,
      [guestPreferencesMessages.guestSpecialOccasion.id]: widgetMessages.resConfirmPageSpecialOccLabel,
      [guestPreferencesMessages.partyDietaryRestriction.id]: widgetMessages.resConfirmPageDietaryRestrictGroupQuestion,
      [guestPreferencesMessages.guestChampagnePreference.id]: widgetMessages.resConfirmPageSearchChampagnePrefLabel,
      [guestPreferencesMessages.guestLiquorPreference.id]: widgetMessages.resConfirmPageLiquorPref,
      [guestPreferencesMessages.guestPreferencesCancel.id]: widgetMessages.resConfirmPageCancelButton,
      [guestPreferencesMessages.guestImageEditButton.id]: widgetMessages.resConfirmPageEditPhoto,
      [guestPreferencesMessages.guestImageReUploadButton.id]: widgetMessages.resConfirmPageReuploadPhoto,
      [paymentHistoryMessages.title.id]: widgetMessages.commonHistoricalTransactionsText,
      [paymentHistoryMessages.chargedText.id]: widgetMessages.commonChargedText,
      [paymentHistoryMessages.refundedText.id]: widgetMessages.commonRefundedText,
      [additionalUpgradesCardMessages.availableUpgradesTitle.id]: widgetMessages.resConfirmPageAvailableUpgradesSubheader,
      [additionalUpgradesCardMessages.availableUpgradesShowMoreTitle.id]: widgetMessages.resConfirmPageViewMore,
      [additionalUpgradesCardMessages.availableUpgradesShowLessTitle.id]: widgetMessages.resConfirmPageViewLess,
      [additionalUpgradesCardMessages.availableUpgradesShowAllTitle.id]: widgetMessages.resConfirmPageViewAllUpgrades,
      [upgradesBannerMessages.title.id]: widgetMessages.resConfirmPageAvailableUpgradesSubheader,
      [upgradesBannerMessages.viewUpgradesButton.id]: widgetMessages.resConfirmPageAvailableUpgradesButton,
      [logoFooterMessages.footerLabel.id]: widgetMessages.resWidgetPoweredByLabel,
      [priorityAlertDetailsMessages.header.id]: widgetMessages.resCancelPageCancelPriorityAlertsSubheader,
      [priorityAlertDetailsMessages.partySize.id]: widgetMessages.resCancelPagePartySize,
      [reservationAvailabilityModalMessages.perPerson.id]: widgetMessages.resWidgetPerPersonLabel,
      [reservationAvailabilityModalMessages.perReservation.id]: widgetMessages.resWidgetPerReservationLabel,
      [reservationAvailabilityModalMessages.confirmButton.id]: widgetMessages.resWidgetSelectButtonLabel,
      [reservationAvailabilityModalMessages.cancellationPolicyHeader.id]: widgetMessages.commonCancellationPolicyHeader,
      [reservationAvailabilityModalMessages.upgradesTitle.id]: widgetMessages.reservationTimeslotIncludedInReservationLabel,
      [reservationAvailabilityModalMessages.descriptionTitle.id]: widgetMessages.reservationTimeslotAboutLabel,
      [reservationAvailabilityMessages.resWidgetPerPersonLabel.id]: widgetMessages.resWidgetPerPersonLabel,
      [reservationAvailabilityMessages.resWidgetPerReservationLabel.id]: widgetMessages.resWidgetPerReservationLabel,
      ...(isLanguageMessages(widgetMessages) && {
        [facebookButtonMessages.text.id]: widgetMessages.resWidgetFacebookCheckoutButton,
        [promoCodeMessages.promoButtonApplyLabel.id]: widgetMessages.resWidgetPromoButtonApplyLabel,
        [promoCodeMessages.promoButtonRemoveLabel.id]: widgetMessages.resWidgetPromoButtonRemoveLabel,
        [promoCodeMessages.promoInputLabel.id]: widgetMessages.resWidgetPromoLabel,
        [promoCodeMessages.promoInputPlaceholder.id]: widgetMessages.resWidgetPromoPlaceholder,
        [promoCodeMessages.promoInputAppliedStatusLabel.id]: widgetMessages.resWidgetPromoAppliedStatusLabel,
        [issueMessages.required.id]: widgetMessages.resWidgetErrorsFieldRequired,
        [issueMessages.invalid.id]: widgetMessages.resWidgetErrorsFieldInvalid,
        [formTimeRangePickerMessages.chooseStartTime.id]: widgetMessages.resWidgetChooseStartTime,
        [formTimeRangePickerMessages.chooseEndTime.id]: widgetMessages.resWidgetChooseEndTime,
        [formTimeRangePickerMessages.errorGreaterThanEnd.id]: widgetMessages.resWidgetTimerangeErrorStartBeforeEnd,
        [formTimeRangePickerMessages.errorSameTime.id]: widgetMessages.resWidgetTimerangeErrorStartEndNotSame,
      }),
    }),
    [isLanguageMessages, widgetMessages]
  )

  const brandColor = widgetSettings.colorPrimary

  const themeOverride = useMemo(
    () => ({
      colors: {
        brandColor,
        venueColor,
      },
    }),
    [brandColor, venueColor]
  )
  return (
    <Root theme="gx" themeOverride={themeOverride} messages={messages} locale={venueSensitiveLocale}>
      {children}
    </Root>
  )
}

export function App() {
  return (
    <Provider store={store}>
      <Router>
        <AppRoot>
          <Switch>
            <Route path={routes.explore.reservations.path}>
              <Reservations />
            </Route>
          </Switch>
        </AppRoot>
      </Router>
    </Provider>
  )
}
