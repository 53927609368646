import { useMemo, useState } from 'react'
import { isPossiblePhoneNumber } from 'react-phone-number-input'
import type { CountryCode } from '@sevenrooms/core/domain'
import { ReservationWidget } from '@sevenrooms/core/domain/constants'
import { z, type ZodSchema, useForm } from '@sevenrooms/core/form'
import { useLocales, getCountryData } from '@sevenrooms/core/locales'
import { DateOnly } from '@sevenrooms/core/timepiece'
import {
  Form,
  FormTimeRangePicker,
  Label,
  TextArea,
  type TimeRangePickerForm,
  useTimeRangePickerForm,
  Button,
} from '@sevenrooms/core/ui-kit/form'
import { useMaxWidthBreakpoint } from '@sevenrooms/core/ui-kit/hooks'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { VStack, HStack, DividerLine, Grid, Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { EmailAddressForm } from '../../../components/EmailAddressForm/EmailAddressForm'
import { FirstNameForm } from '../../../components/FirstNameForm/FirstNameForm'
import { LastNameForm } from '../../../components/LastNameForm/LastNameForm'
import { PhoneNumberForm } from '../../../components/PhoneNumberForm/PhoneNumberForm'
import { useCreateRequestMutation } from '../../../domain/request'
import { useVenue, useVenueGroup } from '../../../hooks'
import { reservationWidgetMessages } from '../../../reservationWidgetMessages'
import { useReservationFormState, useModals } from '../../../store'
import { requestSubmitted } from '../../../utils'

export type CreateRequestForm = ZodSchema<typeof useCreateRequestFormSchema>
export const useCreateRequestFormSchema = () => {
  const { formatMessage } = useLocales()
  const { startOfDayTime } = useVenue()
  const timeRange = useTimeRangePickerForm({ startOfDayTime, nullable: false, allowSameTime: true })
  const requiredErrorMessage = formatMessage(reservationWidgetMessages.resWidgetErrorsFieldRequired)
  const invalidErrorMessage = formatMessage(reservationWidgetMessages.resWidgetErrorsFieldInvalid)

  return useMemo(
    () =>
      z
        .object({
          emailAddress: z.string().min(1, { message: requiredErrorMessage }).email({ message: invalidErrorMessage }),
          firstName: z.string().min(1, { message: requiredErrorMessage }),
          lastName: z.string().min(1, { message: requiredErrorMessage }),
          notes: z.string(),
          phoneCountryCode: z.custom<CountryCode>(),
          phoneDialCode: z.string().min(1),
          phoneNumber: z.string().min(1, { message: requiredErrorMessage }),
          timeRange,
        })
        .superRefine((val, ctx) => {
          if (!isPossiblePhoneNumber(val.phoneNumber)) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: invalidErrorMessage,
              path: ['phoneNumber'],
            })
          }
        }),
    [invalidErrorMessage, requiredErrorMessage, timeRange]
  )
}

export function CreateRequest({ onClose, validRequestTimes }: { onClose: () => void; validRequestTimes: string[] }) {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { formatMessage } = useLocales()
  const isMobile = useMaxWidthBreakpoint('s')
  const [createRequest, { isLoading }] = useCreateRequestMutation({
    fixedCacheKey: 'reservation-create-request',
  })
  const { id: venueId, urlKey } = useVenue()
  const { lockedFields } = useVenueGroup()
  const { formState: createReservationFormState, updateFormState } = useReservationFormState()
  const { showModal, showErrorModal } = useModals()

  const phoneDialCode = getCountryData(createReservationFormState.phoneCountryCode)?.dialCode ?? '1'
  const requestFormSchema = useCreateRequestFormSchema()

  const timeRange: TimeRangePickerForm =
    createReservationFormState.startTime === ReservationWidget.AllTimesOption
      ? {
          startTime: null,
          endTime: null,
        }
      : {
          startTime: validRequestTimes[0] || null,
          endTime: validRequestTimes[validRequestTimes.length - 1] || null,
        }

  const form = useForm(requestFormSchema, {
    mode: 'onSubmit',
    defaultValues: {
      emailAddress: createReservationFormState.emailAddress,
      firstName: createReservationFormState.firstName,
      lastName: createReservationFormState.lastName,
      notes: createReservationFormState.notes,
      phoneCountryCode: createReservationFormState.phoneCountryCode,
      phoneDialCode,
      phoneNumber: createReservationFormState.phoneNumber,
      timeRange,
    },
  })
  const { field, getValues, setError } = form
  const disabled = isSubmitting || isLoading

  const saveOnClose = () => {
    updateFormState({ ...createReservationFormState, ...getValues() })
    onClose()
  }

  const onSubmit = async (data: CreateRequestForm) => {
    try {
      setIsSubmitting(true)
      updateFormState({ ...createReservationFormState, ...data })
      const timeRange = data.timeRange as { startTime: string; endTime: string }
      await createRequest({
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.emailAddress,
        phoneNumber: data.phoneNumber,
        countryCode: data.phoneCountryCode,
        phoneDialCode: data.phoneDialCode,
        date: createReservationFormState.startDate,
        startTime: timeRange.startTime,
        endTime: timeRange.endTime,
        partySize: createReservationFormState.partySize,
        preferredLanguageCode: createReservationFormState.preferredLanguage,
        requestClass: 'table',
        clientRequest: data.notes,
        requestSmsOptIn: false,
        venueId,
        // eslint-disable-next-line no-warning-comments
        // TODO GX-2914
        channel: 'SEVENROOMS_WIDGET',
        trackingSlug: createReservationFormState.trackingSlug,
        clientId: undefined,
        experienceId: undefined,
        referralId: undefined,
      }).unwrap()
      showModal('createRequestSuccess')
      requestSubmitted(
        urlKey,
        createReservationFormState.startDate,
        createReservationFormState.partySize,
        timeRange.startTime,
        timeRange.endTime
      )
    } catch (error) {
      if (typeof error === 'string') {
        setError('timeRange', {
          message: formatMessage(reservationWidgetMessages.resWidgetRequestsAlreadyExistsError),
        })
      } else {
        showErrorModal()
      }
    } finally {
      setIsSubmitting(false)
    }
  }

  const handleInvalid = (data: unknown) => {
    // Remove before mvp, useful for debugging for now.
    // eslint-disable-next-line no-console
    console.log(data)
  }

  const startDate = DateOnly.fromSafe(createReservationFormState.startDate)?.formatNYearFMonthNDayFWeek()
  return (
    <Form onSubmit={onSubmit} onInvalid={handleInvalid} {...form}>
      <Modal
        data-test="sr-create-request-modal"
        width="100%"
        ariaLabel={formatMessage(reservationWidgetMessages.resWidgetRequestsSubmitRequestTitle)}
      >
        <ModalHeader onClose={saveOnClose}>
          <VStack spacing="s">
            <ModalTitle
              data-test="sr-create-request-modal-title"
              title={formatMessage(reservationWidgetMessages.resWidgetRequestDetailsLabel)}
            />
          </VStack>
        </ModalHeader>
        <ModalBody>
          <VStack mb="l" mt="m" spacing="m">
            <HStack spacing="s" alignItems="center">
              <Icon name="GX-calendar-range" size="lg" />
              <Text data-test="sr-create-request-modal-date">{startDate}</Text>
            </HStack>
            <HStack spacing="s" alignItems="center">
              <Icon name="GX-account-multiple" size="lg" />
              <Text data-test="sr-create-request-modal-party-size">
                {`${createReservationFormState.partySize} ${
                  createReservationFormState.partySize === 1
                    ? formatMessage(reservationWidgetMessages.commonGuestLabel)
                    : formatMessage(reservationWidgetMessages.commonGuestsLabel)
                }`}
              </Text>
            </HStack>
          </VStack>
          <VStack spacing="m">
            <Label primary={`${formatMessage(reservationWidgetMessages.resWidgetPriorityAlertsReservationTimeBetween)}*`}>
              <FormTimeRangePicker
                data-test="sr-create-request-modal-time-range"
                field={field.prop('timeRange')}
                disabled={disabled}
                timeSlots={validRequestTimes}
                selectProps={{ withNativeSupport: true, zIndexLayer: 'modal' }}
              />
            </Label>
            <Label primary={formatMessage(reservationWidgetMessages.resWidgetReservationNotesHeader)}>
              <TextArea fullWidth resize="none" field={field.prop('notes')} disabled={disabled} />
            </Label>
            <DividerLine ml="none" mr="none" />
            <Grid
              gridAutoColumns="auto auto"
              gridTemplateColumns={`repeat(${isMobile ? 1 : 2}, minmax(0, 1fr))`}
              gap="m"
              alignItems="start"
            >
              <FirstNameForm firstNameField={field.prop('firstName')} disabled={disabled || lockedFields.firstName} />
              <LastNameForm lastNameField={field.prop('lastName')} disabled={disabled || lockedFields.lastName} />
              <EmailAddressForm emailAddressField={field.prop('emailAddress')} disabled={disabled || lockedFields.email} />
              <PhoneNumberForm
                phoneNumberField={field.prop('phoneNumber')}
                disabled={disabled || lockedFields.phoneNumber}
                phoneCountryCodeField={field.prop('phoneCountryCode')}
                phoneDialCodeField={field.prop('phoneDialCode')}
              />
            </Grid>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button
            data-test="submit-a-request-button"
            disabled={disabled}
            data-test-id="sr-create-request-modal-set-alert"
            type="submit"
            fullWidth
            size="l"
          >
            {formatMessage(reservationWidgetMessages.resWidgetSubmitRequestButton)}
          </Button>
        </ModalFooter>
      </Modal>
    </Form>
  )
}
