import { skipToken } from '@reduxjs/toolkit/query'
import { useCallback, useEffect, useMemo } from 'react'
import type { CountryCode, Upgrade } from '@sevenrooms/core/domain'
import { FormatService } from '@sevenrooms/core/locales'
import { Surface, useNavigation } from '@sevenrooms/core/navigation'
import { routes } from '@sevenrooms/core/routes'
import { EmbeddedMap } from '@sevenrooms/core/ui-kit/core'
import { useMaxWidthBreakpoint } from '@sevenrooms/core/ui-kit/hooks'
import { VStack, Window, Box } from '@sevenrooms/core/ui-kit/layout'
import { filterNullish } from '@sevenrooms/core/utils'
import {
  GuestPreferences,
  CancelModal,
  UnableModifyModal,
  UnableAddUpgradesModal,
  ReservationDetailCard,
  VenueDetails,
  WidgetPortal,
} from '../../components'
import { useVenue, useManageReservationRoute, useWidgetSettings } from '../../hooks'
import { useGetManageReservationDetailsQuery, useLazyGetManageReservationDetailsQuery, useModals } from '../../store'
import { calculateCategoryFees } from '../../utils/calculateFees'
import { ActionPanel } from './ActionPanel'
import { AdditionalUpgradesCard } from './AdditionalUpgradesCard'
import { AddUpgradesModal } from './AddUpgradesModal'
import { ManageReservationHeader, type ManageReservationHeaderProps } from './ManageReservationHeader'
import { ManageReservationSkeleton } from './ManageReservationSkeleton'
import { PaymentDetails } from './PaymentDetails'
import { PolicyDetails } from './PolicyDetails'
import { UpgradeDetails } from './UpgradeDetails'
import { UpgradesBanner } from './UpgradesBanner'

const managePath = routes.explore.reservations.manage

export function Manage() {
  const { closeSurfaceHref, push } = useNavigation()
  const { showErrorModal } = useModals()
  const widgetSettings = useWidgetSettings()
  const venue = useVenue()
  const { lang, token, venueKey, isConfirm, isSuccess, isSuccessEdit, linkToModifyReservation, linkToPurchaseUpgrades } =
    useManageReservationRoute()
  const isMobile = useMaxWidthBreakpoint('s')

  const { isError: isGetDetailsError, isLoading, data } = useGetManageReservationDetailsQuery(token ? { lang, token } : skipToken)
  const [getManageReservationDetails, { isFetching: isReFetchingDetails }] = useLazyGetManageReservationDetailsQuery()
  const { actual, bookingAccess, charges = [], calendarLinks, policies, actualFlags, venueTags, additionalUpgrades, upsell } = data ?? {}

  const guestContact = getGuestContact(
    actual?.lastConfirmationMethod,
    actual?.email,
    actual?.phoneNumber,
    actual?.phoneNumberLocale as CountryCode
  )

  const isError = isGetDetailsError || !venueKey || !token
  const hasAdditionalUpgrades = !!additionalUpgrades?.data.length

  const onModifyBtnClick = useCallback(async () => {
    try {
      if (data?.actualFlags?.isEditable) {
        window.open(linkToModifyReservation, '_blank')
      } else {
        push(managePath.modifyModal, {
          params: { venueKey: venue.urlKey },
        })
      }
    } catch {
      showErrorModal()
    }
  }, [data?.actualFlags?.isEditable, linkToModifyReservation, push, showErrorModal, venue.urlKey])

  const onCancelBtnClick = useCallback(async () => {
    try {
      await getManageReservationDetails({ token: token as string, lang }).unwrap()
      push(managePath.cancelModal, {
        params: { venueKey: venue.urlKey },
      })
    } catch {
      showErrorModal()
    }
  }, [getManageReservationDetails, lang, push, showErrorModal, token, venue.urlKey])

  const { sendConfirmationsResWidgetBookings } = venue

  const onAddUpgradesBtnClick = useCallback(async () => {
    window.open(linkToPurchaseUpgrades, '_blank')
  }, [linkToPurchaseUpgrades])

  const onMapClick = useMemo(() => {
    if (!venue.gmapsLinkByPlaceId) {
      // we return undefined here, so that the EmbeddedMap component doesn't render the click wrapper
      return undefined
    }
    const clickFunc = () => {
      window.open(venue.gmapsLinkByPlaceId, '_blank')
    }
    return clickFunc
  }, [venue.gmapsLinkByPlaceId])

  useEffect(() => {
    if (isError) {
      showErrorModal()
    }
  }, [isError, showErrorModal])

  if (isLoading || isError) {
    return <ManageReservationSkeleton />
  }
  const imageUrl = `/.h/download/${widgetSettings.headerImgPhotoDict.mega || widgetSettings.headerImgPhotoDict.medium}`
  let flowType: ManageReservationHeaderProps['flowType']
  if (!hasAdditionalUpgrades && isConfirm) {
    flowType = 'confirmation'
  } else if (isSuccess) {
    flowType = 'create'
  } else if (isSuccessEdit) {
    flowType = 'update'
  }

  const upgrades: Upgrade[] = (actual?.selectedUpsellsInformation ?? [])
    .map(upgrade => {
      if (!widgetSettings.isFeesInPriceDisplayed) {
        return upgrade
      }

      if (!upsell) {
        return undefined
      }

      const { categories, inventories } = upsell
      const upgradeInventory = inventories.find(inventory => inventory.id === upgrade.id)
      if (!upgradeInventory) {
        return undefined
      }

      const upgradeCategory = categories.find(category => category.id === upgradeInventory.categoryId)
      if (!upgradeCategory) {
        return undefined
      }

      return {
        ...upgrade,
        fees: calculateCategoryFees(upgrade.price, upgradeCategory, venue.defaultServiceCharge, venue.defaultGratuity),
      }
    })
    .filter(filterNullish)

  return (
    <>
      <VStack width="100%" maxWidth={isMobile ? '100%' : '428px'} spacing="lm">
        {isMobile && (
          <ManageReservationHeader
            flowType={flowType}
            guestContact={guestContact}
            alignItems="center"
            flexDirection="column"
            bookingConfirmationEnabled={sendConfirmationsResWidgetBookings}
          />
        )}
        <ReservationDetailCard
          calendarLinks={calendarLinks}
          imageUrl={imageUrl}
          venueName={venue.name}
          timeSlotDescription={bookingAccess?.timeSlotDescription}
          guestCount={actual?.maxGuests}
          maleCount={actual?.mfRatioMale}
          femaleCount={actual?.mfRatioFemale}
          guestDisplayName={actual?.clientDisplayNameWithSalutation}
          reservationDate={actual?.date}
          reservationTime={actual?.arrivalTime}
          reservationDuration={venue.includeEndTime ? actual?.duration : undefined}
          // minPrice is undefined in case of minPriceFormatted is NO_MIN or COMP, we hide row for these values
          reservationMinimum={actual?.minPrice ? actual?.minPriceFormatted : undefined}
          reservationReferenceId={actual?.referenceCode}
          seatingAreaDescription={venue.includeSeatingArea ? actual?.venueSeatingAreaName : undefined}
          fallback={
            venue.gmapsEmbedUrl ? (
              <EmbeddedMap
                clickTestId="venue-map"
                embeddedMapUrl={venue.gmapsEmbedUrl}
                height="240px"
                width="100%"
                onMapClick={onMapClick}
              />
            ) : (
              <></>
            )
          }
        />
      </VStack>
      <VStack width="100%">
        {!isMobile && (
          <Box mb="lm">
            <ManageReservationHeader
              flowType={flowType}
              guestContact={guestContact}
              alignItems="left"
              bookingConfirmationEnabled={sendConfirmationsResWidgetBookings}
            />
          </Box>
        )}
        <VStack spacing="l">
          <GuestPreferences
            actualId={actual?.id}
            guestCount={actual?.maxGuests}
            venueTags={venueTags}
            clientId={actual?.venueGroupClientId}
            venueId={venue.id}
            clientPhoto={actual?.clientPhoto}
            clientDisplayName={actual?.clientDisplayName}
            showDietaryRestrictions={widgetSettings.enableDietaryRestrictionsConfirmation}
            showAdditionalProfileInformation={widgetSettings.enableAdditionalProfileInfoConfirmation}
            showSpecialOccasions={widgetSettings.enableSpecialOccasionsConfirmation}
            requireGdprOptin={widgetSettings.requireDietaryTagGdprOptInConfirmation}
            champagnePreferenceTags={actual?.champagnePreferenceTags}
            clientDietaryPreferenceTags={actual?.clientDietaryPreferenceTags}
            liquorPreferenceTags={actual?.liquorPreferenceTags}
            partyDietaryPreferenceTags={actual?.partyDietaryPreferenceTags}
            specialOccasionTags={actual?.specialOccasionTags}
            otherInfoText={actual?.customQuestionResponseNote}
            agreedToGdprDietaryOptin={actual?.agreedToGdprDietaryOptin}
            onError={showErrorModal}
          />
          <PaymentDetails charges={charges} lang={lang} currencyCode={venue.currencyCode} />
          <UpgradeDetails upgrades={upgrades} currencyCode={venue.currencyCode} />
          {hasAdditionalUpgrades && (
            <AdditionalUpgradesCard
              upgrades={additionalUpgrades?.data}
              hasMore={additionalUpgrades.hasMoreUpgrades}
              onAdd={onAddUpgradesBtnClick}
              currencyCode={venue.currencyCode}
            />
          )}
          <VStack spacing="lm">
            <PolicyDetails
              dataTestId="reservation-widget-policy-details"
              showSpecialAttentionMessage={venue.includeSpecialAttentionMessage}
              {...policies}
            />
            <ActionPanel
              isEditable={venue.showCancelLink && actualFlags?.isEditable}
              isCancelable={venue.showCancelLink && actualFlags?.isCancelable}
              onModifyBtnClick={onModifyBtnClick}
              onCancelBtnClick={onCancelBtnClick}
              disabled={isReFetchingDetails}
            />
          </VStack>
          <VenueDetails
            venue={venue}
            // need to hide map in venue details if we have it in reservation details card
            gmapsEmbedUrl={widgetSettings.headerImgUrl ? venue.gmapsEmbedUrl : undefined}
          />
        </VStack>
      </VStack>
      {!!additionalUpgrades?.data.length && (
        <>
          <Surface destination={managePath.addUpgradesModal}>
            <Window>
              <AddUpgradesModal
                upgrades={additionalUpgrades.data}
                onConfirm={onAddUpgradesBtnClick}
                closeHref={closeSurfaceHref(managePath.addUpgradesModal, {
                  params: { venueKey: venue.urlKey },
                })}
                currencyCode={venue.currencyCode}
              />
            </Window>
          </Surface>
          <WidgetPortal portalId="manage-res-portal">
            <Box mb="lm">
              <UpgradesBanner onConfirm={onAddUpgradesBtnClick} />
            </Box>
          </WidgetPortal>
        </>
      )}
      <Surface destination={managePath.modifyModal}>
        <Window>
          <UnableModifyModal
            closeHref={closeSurfaceHref(managePath.modifyModal, {
              params: { venueKey: venue.urlKey },
            })}
          />
        </Window>
      </Surface>
      <Surface destination={managePath.cancelModal}>
        <Window>
          <CancelModal
            closeHref={closeSurfaceHref(managePath.cancelModal, {
              params: { venueKey: venue.urlKey },
            })}
          />
        </Window>
      </Surface>
      <Surface destination={managePath.errorUpgrades}>
        <Window>
          <UnableAddUpgradesModal
            closeHref={closeSurfaceHref(managePath.errorUpgrades, {
              params: { venueKey: venue.urlKey },
            })}
          />
        </Window>
      </Surface>
    </>
  )
}

const getGuestContact = (
  lastConfirmationMethod?: string | null,
  email?: string | null,
  phoneNumber?: string | null,
  phoneNumberLocale?: CountryCode | null
) => {
  if (lastConfirmationMethod === 'SMS' && phoneNumber && phoneNumberLocale) {
    return FormatService.formatPhoneNumber(phoneNumber, phoneNumberLocale)
  }
  return email
}
