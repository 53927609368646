import { combineReducers } from 'redux'
import { paymentApi } from '@sevenrooms/payments'
import { baseReservationWidgetApi } from '../hooks'
import { clientLoginReducer, clientLoginName } from './clientLoginSlice'
import { modalsReducer } from './modalsSlice'
import { reservationFormReducer } from './reservationFormSlice'
import { reservationNavigationSlice } from './reservationNavigation'

export const rootReducer = combineReducers({
  [baseReservationWidgetApi.reducerPath]: baseReservationWidgetApi.reducer,
  [paymentApi.reducerPath]: paymentApi.reducer,
  app: (state = {}) => state,
  reservationForm: reservationFormReducer,
  reservationNavigation: reservationNavigationSlice.reducer,
  modals: modalsReducer,
  [clientLoginName]: clientLoginReducer,
  selectedLanguageStrings: (state = {}) => state,
  templateData: (state = {}) => state,
  venueInfo: (state = {}) => state,
  widgetSettings: (state = {}) => state,
  analyticsSettings: (state = {}) => state,
  privateEventsSettings: (state = {}) => state,
  salutationOptions: (state = {}) => state,
  clientInfo: (state = {}) => state,
})
