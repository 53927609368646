/* eslint-disable camelcase */
import { useNavigation } from '@sevenrooms/core/navigation'
import { routes } from '@sevenrooms/core/routes'

export const useAlertsRoute = () => {
  const { matchQuery } = useNavigation()
  const { request_id, party_size, start_time, end_time, date } = matchQuery(routes.explore.reservations.alerts) ?? {}

  return {
    requestId: request_id ?? '',
    partySize: party_size,
    startTime: start_time,
    endTime: end_time,
    date,
  }
}
