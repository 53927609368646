import type { LanguageCode } from '@sevenrooms/core/api'
import type { CountryCode, PromoCode } from '@sevenrooms/core/domain'
import { useForm } from '@sevenrooms/core/form'
import { getCountryData } from '@sevenrooms/core/locales'
import { useCheckoutFormSchema } from './Checkout.zod'

type CheckoutOptionalType = 'Required' | 'Optional' | 'Hidden'

export interface UseCheckoutFormProps {
  agreedCustomCheckoutPolicy: boolean
  agreedToAboveAgeConsentOn: boolean
  agreedToBookingPolicy: boolean
  agreedToReservationSmsOptIn: boolean
  agreedToVenueGroupMarketingOptIn: boolean
  agreedToVenueSpecificMarketingOptIn: boolean
  agreedToVenueSmsMarketingOptIn: boolean
  birthdayDay: string | null
  birthdayMonth: string | null
  birthdayType: CheckoutOptionalType
  emailAddress: string
  firstName: string
  gratuityPercentage: number | null
  hasAgeToConsent: boolean
  hasReCaptcha: boolean
  hasBillingAddress: boolean
  hasCustomCheckoutPolicy: boolean
  lastName: string
  phoneNumber?: string
  phoneCountryCode: CountryCode
  postalCode: string
  postalCodeType: CheckoutOptionalType
  preferredLanguage: LanguageCode
  reCaptcha?: string
  hasRequiredClientSelectableGratuity: boolean
  hasBookingPolicy: boolean
  hasCustomPaymentForm: boolean
  clientId?: string
  promoCode?: PromoCode
  salutation: string | null
  salutationType: CheckoutOptionalType
}

export function useCheckoutForm({
  agreedCustomCheckoutPolicy,
  agreedToAboveAgeConsentOn,
  agreedToBookingPolicy,
  agreedToReservationSmsOptIn,
  agreedToVenueGroupMarketingOptIn,
  agreedToVenueSpecificMarketingOptIn,
  agreedToVenueSmsMarketingOptIn,
  birthdayDay,
  birthdayMonth,
  birthdayType,
  emailAddress,
  firstName,
  gratuityPercentage,
  hasAgeToConsent,
  hasReCaptcha,
  hasCustomCheckoutPolicy,
  hasRequiredClientSelectableGratuity,
  hasBookingPolicy,
  lastName,
  phoneNumber,
  phoneCountryCode,
  postalCode,
  postalCodeType,
  preferredLanguage,
  clientId,
  promoCode,
  hasBillingAddress,
  hasCustomPaymentForm,
  salutation,
  salutationType,
}: UseCheckoutFormProps) {
  const CheckoutFormSchema = useCheckoutFormSchema({
    birthdayType,
    hasReCaptcha,
    postalCodeType,
    hasAgeToConsent,
    hasCustomCheckoutPolicy,
    hasRequiredClientSelectableGratuity,
    hasBookingPolicy,
    hasBillingAddress,
    hasCustomPaymentForm,
    salutationType,
  })
  const phoneDialCode = getCountryData(phoneCountryCode)?.dialCode ?? ''

  return useForm(CheckoutFormSchema, {
    defaultValues: {
      agreedCustomCheckoutPolicy,
      agreedToAboveAgeConsentOn,
      agreedToBookingPolicy,
      agreedToReservationSmsOptIn,
      agreedToVenueGroupMarketingOptIn,
      agreedToVenueSpecificMarketingOptIn,
      agreedToVenueSmsMarketingOptIn,
      birthdayDay,
      birthdayMonth,
      emailAddress,
      firstName,
      gratuityPercentage,
      lastName,
      phoneCountryCode,
      phoneDialCode,
      phoneNumber,
      postalCode,
      preferredLanguage,
      reCaptcha: null,
      socialMediaLoginSite: 'guest',
      clientId,
      promoCode,
      salutation,
    },
    mode: 'onSubmit',
  })
}
