import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import FocusLock from 'react-focus-lock'
import mergeRefs from 'react-merge-refs'
import { TouchScrollable } from 'react-scrolllock'
import type { LanguageCode } from '@sevenrooms/core/api'
import { type Field, useWatch } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { MonthDayOnly } from '@sevenrooms/core/timepiece'
import { useTheme } from '@sevenrooms/core/ui-kit'
import type { PickerValue } from '@sevenrooms/core/ui-kit/core'
import { FormCalendar } from '@sevenrooms/core/ui-kit/form'
import { useMaxWidthBreakpoint, useWindowSize } from '@sevenrooms/core/ui-kit/hooks'
import { Flex, Popover, type PopoverTargetRenderProps } from '@sevenrooms/core/ui-kit/layout'
import { ReservationFilterButton } from '../../Reservations/Search/ReservationFilterButton'
import { reservationWidgetMessages } from '../../reservationWidgetMessages'
import { DEFAULT_HEADER_IMAGE_HEIGHT } from '../HeaderImage'
import { getRelativeFormattedDate } from './getRelativeFormattedDate'

interface ReservationDayPickerFormProps<T extends boolean> {
  label: string
  dataTest: string
  value: Field<PickerValue<T>>
  today?: Date
  isLoading?: boolean
  isDayBlocked?: (day: Date) => boolean
  onChange?: (date: PickerValue<T>) => void
  locale: LanguageCode
  scrollLock?: boolean
  scrollTopOnMobile?: boolean
  popoverWidth?: number
  required?: T
}

export function ReservationDayPickerForm<T extends boolean>({
  isLoading = false,
  dataTest,
  label,
  value,
  today,
  isDayBlocked,
  onChange,
  scrollLock,
  scrollTopOnMobile,
  locale,
  popoverWidth,
  required,
}: ReservationDayPickerFormProps<T>) {
  const { formatMessage } = useLocales()
  const theme = useTheme()
  const buttonRef = useRef<HTMLButtonElement>()
  const isSmallDesktop = useMaxWidthBreakpoint('l')
  const isSmallMobile = useMaxWidthBreakpoint('s')
  const dateValue = useWatch(value)

  const formattedDate = useMemo(() => {
    if (!dateValue) {
      return formatMessage(reservationWidgetMessages.resWidgetPrivateEventsDatePlaceholder)
    }
    return isSmallMobile ? MonthDayOnly.fromDate(dateValue).formatSMonthNDay() : getRelativeFormattedDate({ date: dateValue })
  }, [dateValue, formatMessage, isSmallMobile])

  const [isOpen, setIsOpen] = useState(false)
  const size = useWindowSize()

  const closePopper = useCallback(() => {
    setIsOpen(false)
  }, [])

  const onDayPickerChange = useCallback(
    (date: PickerValue<T>) => {
      onChange?.(date)
      closePopper()
    },
    [closePopper, onChange]
  )

  useEffect(() => {
    // close popover on resize
    closePopper()
  }, [closePopper, size])

  const onButtonClick = useCallback(() => {
    if (isSmallMobile && scrollTopOnMobile && !isOpen) {
      // for better user experience scroll top to fit day picker on screens with small height
      window.scroll({ top: DEFAULT_HEADER_IMAGE_HEIGHT, behavior: 'smooth' })
    }
    setIsOpen(!isOpen)
  }, [isOpen, isSmallMobile, scrollTopOnMobile])

  const renderTarget = useCallback(
    (props: PopoverTargetRenderProps<HTMLButtonElement>) => (
      <ReservationFilterButton
        ref={mergeRefs([buttonRef, props.ref])}
        data-test={dataTest}
        isOpen={isOpen}
        isLoading={isLoading}
        label={label}
        location="right"
        onClick={onButtonClick}
        value={formattedDate}
      />
    ),
    [dataTest, isOpen, isLoading, label, onButtonClick, formattedDate]
  )

  const renderContent = () => (
    <TouchScrollable>
      <Flex
        justifyContent="center"
        backgroundColor="primaryBackground"
        boxShadow="primary"
        borderColor="borders"
        borderRadius="s"
        width={popoverWidth ? `${popoverWidth}px` : undefined}
        overflowY="scroll"
        overflowX="hidden"
        // workaround to make day picker scrollable, didn't find a better solution with current Popover implementation
        maxHeight={isSmallMobile ? 'calc(100vh - 130px)' : 'calc(100vh - 170px)'}
      >
        <FocusLock returnFocus>
          <FormCalendar
            today={today}
            value={value}
            dataTest={`${dataTest}-day-picker`}
            onChange={onDayPickerChange}
            numberOfMonths={isSmallDesktop ? 1 : 2}
            locale={locale}
            disabled={isDayBlocked}
            initialFocus
            required={required}
          />
        </FocusLock>
      </Flex>
    </TouchScrollable>
  )

  return (
    <Popover
      targetOffset={parseInt(theme.spacing.xs)}
      bodyOffset={0}
      alignment="bottomRight"
      show={isOpen}
      onClose={closePopper}
      content={renderContent}
      scrollLock={scrollLock}
      useSmartAlignment={false}
    >
      {renderTarget}
    </Popover>
  )
}
