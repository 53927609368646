import { useCallback, useMemo } from 'react'
import { useForm } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { DateOnly, Formats, legacyJSDateAdapter } from '@sevenrooms/core/timepiece'
import { Form } from '@sevenrooms/core/ui-kit/form'
import { useMaxWidthBreakpoint, useThrottledResizeObserver } from '@sevenrooms/core/ui-kit/hooks'
import { Box } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { ReservationDayPickerForm } from '../../../components/ReservationDayPickerForm'
import { useLanguageStrings, useVenue, useWidgetLanguage, useWidgetSettings } from '../../../hooks'
import { reservationWidgetMessages } from '../../../reservationWidgetMessages'
import { useReservationFormState } from '../../../store'
import { useListAvailabilityDatesQuery } from '../AvailabilityDateApi'
import { ReservationSearchInputGroup, StyledSearchInputGridContainer } from '../ReservationSearchInputGroup'
import { ReservationSelectForm } from '../ReservationSelectForm'
import { getPartySizeSelectOptions } from '../utils'
import { usePrivateEventsSearchFormSchema } from './PrivateEventsTab.zod'

interface PrivateEventsTabProps {}

export function PrivateEventsTab(_props: PrivateEventsTabProps) {
  const { formatMessage } = useLocales()
  const { venueToday, urlKey } = useVenue()
  const { headerImgUrl, maxDaysOut, minGuests, maxGuests } = useWidgetSettings()
  const { selectedLanguage } = useWidgetLanguage()
  const { isFetching: isFetchingLanguage } = useLanguageStrings()
  const isSmallDesktop = useMaxWidthBreakpoint('l')
  const isSmallMobile = useMaxWidthBreakpoint('xs')
  const { ref: reservationSearchInputGroupRef, width: reservationSearchInputGroup } = useThrottledResizeObserver(50, 'border-box')

  const currentDateAtVenue = DateOnly.fromSafe(venueToday)?.toJsDate() || new Date()

  const { isFetching: isDatesFetching, data: availabilityDates = [] } = useListAvailabilityDatesQuery({
    venue: urlKey,
    numDays: maxDaysOut,
    startDate: legacyJSDateAdapter(new Date(), Formats.MonthDayYearByDash),
  })

  const isDayBlocked = useCallback((date: Date) => !availabilityDates.includes(DateOnly.fromDate(date).toIso()), [availabilityDates])

  const partySizeOptions = useMemo(
    () =>
      getPartySizeSelectOptions({
        minGuests,
        maxGuests,
      }),
    [maxGuests, minGuests]
  )

  const {
    formState: { ...searchFormData },
    updateFormState,
  } = useReservationFormState()

  const privateEventsSearchFormSchema = usePrivateEventsSearchFormSchema()
  const form = useForm(privateEventsSearchFormSchema, {
    defaultValues: {
      privateEventsPartySize: searchFormData.privateEventsPartySize,
      privateEventsStartDate: searchFormData.privateEventsStartDate ? DateOnly.from(searchFormData.startDate).toJsDate() : undefined,
    },
  })
  const { field } = form

  return (
    <>
      <Box mt="sm" mb="m">
        <Text textStyle="h1">{formatMessage(reservationWidgetMessages.resWidgetPrivateEventsTabHeader)}</Text>
      </Box>
      <StyledSearchInputGridContainer>
        <Form {...form} onSubmit={() => {}} onInvalid={() => {}}>
          <ReservationSearchInputGroup columnsCount={2} ref={reservationSearchInputGroupRef}>
            <ReservationSelectForm
              id="reservation-party-size"
              label={formatMessage(reservationWidgetMessages.resWidgetGuestsLabel)}
              placeholder={formatMessage(reservationWidgetMessages.resWidgetPrivateEventsPartySizePlaceholder)}
              dataTest="sr-reservation-party-size"
              location="left"
              isLoading={isFetchingLanguage}
              selectOptions={partySizeOptions}
              field={field.prop('privateEventsPartySize')}
              withEmpty
              onChange={value => {
                updateFormState({ privateEventsPartySize: value })
              }}
            />
            <ReservationDayPickerForm
              label={formatMessage(reservationWidgetMessages.resWidgetDatePickerLabel)}
              isLoading={isFetchingLanguage || isDatesFetching}
              isDayBlocked={isDayBlocked}
              dataTest="sr-reservation-date"
              value={field.prop('privateEventsStartDate')}
              today={currentDateAtVenue}
              locale={selectedLanguage}
              popoverWidth={isSmallDesktop && !isSmallMobile ? undefined : reservationSearchInputGroup}
              onChange={(value: Date | undefined) => {
                updateFormState({ privateEventsStartDate: value ? DateOnly.fromDate(value).toIso() : undefined })
              }}
              scrollTopOnMobile={!!headerImgUrl}
            />
          </ReservationSearchInputGroup>
        </Form>
      </StyledSearchInputGridContainer>
    </>
  )
}
