import { skipToken } from '@reduxjs/toolkit/query'
import { useEffect, useState } from 'react'
import type { CountryCode } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { Route, Switch, Redirect } from '@sevenrooms/core/navigation'
import { routes } from '@sevenrooms/core/routes'
import { useRequestDetailQuery } from '../domain/request'
import { useReservationsRoute, useVenue } from '../hooks'
import { reservationWidgetMessages } from '../reservationWidgetMessages'
import { useModals, useReservationFormState } from '../store'
import { CheckoutPage } from './Checkout'
import { SearchAndAvailability } from './Search'
import { UpgradesPage } from './Upgrades'

const createPath = routes.explore.reservations.create

export function Create() {
  const { formatMessage } = useLocales()
  const venue = useVenue()
  const { showErrorModal } = useModals()
  const { requestId, clearQueryParams, hasPaymentError } = useReservationsRoute()
  const { data, isLoading, isError } = useRequestDetailQuery(requestId ? { requestId } : skipToken)
  const { updateFormState } = useReservationFormState()
  const [shouldDisplayLoader, setShouldDisplayLoader] = useState(true)

  useEffect(() => {
    if (isError) {
      showErrorModal()
    }
  }, [isError, showErrorModal])

  useEffect(() => {
    if (hasPaymentError) {
      showErrorModal(
        formatMessage(reservationWidgetMessages.resPaymentFailedModalBody),
        formatMessage(reservationWidgetMessages.resPaymentFailedModalHeader),
        true
      )
    }
    // formatMessage dependency triggers use effect to be executed on each render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasPaymentError, showErrorModal])

  useEffect(() => {
    if (!isLoading && data) {
      setShouldDisplayLoader(false)

      let errorMessage
      if (data.venueId !== venue.id) {
        errorMessage = formatMessage(reservationWidgetMessages.resWidgetRequestErrorMessageInvalid)
      } else if (!data.isActive) {
        errorMessage = formatMessage(reservationWidgetMessages.resWidgetRequestErrorMessageInactive)
      }

      if (errorMessage) {
        showErrorModal(errorMessage)
        clearQueryParams('request_id')
        return
      }

      updateFormState({
        startDate: data.date,
        partySize: data.maxGuests,
        emailAddress: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
        phoneNumber: data.phoneNumber,
        phoneCountryCode: data.phoneNumberLocale?.toLowerCase() as CountryCode,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading])

  return (
    <Switch>
      <Route path={createPath.search.path}>
        <SearchAndAvailability shouldDisplayLoader={!!(requestId && shouldDisplayLoader)} />
      </Route>
      <Route path={createPath.upgrades.path}>
        <UpgradesPage />
      </Route>
      <Route path={createPath.checkout.path}>
        <CheckoutPage />
      </Route>
      <Redirect to={createPath.search.path} />
    </Switch>
  )
}
