import { useLocales } from '@sevenrooms/core/locales'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { useMaxWidthBreakpoint } from '@sevenrooms/core/ui-kit/hooks'
import { Image, VStack, StackResponsive, Flex } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { reservationWidgetMessages } from '../../../reservationWidgetMessages'

export interface PrivateEventsBannerProps {
  bannerImgUrl?: string | null
  onExploreClick?: () => void
}

const imageWidth = '100px'
const imageHeight = '60px'
const imageMobileHeight = '124px'
const buttonWidth = '220px'

export function PrivateEventsBanner({ bannerImgUrl, onExploreClick }: PrivateEventsBannerProps) {
  const { formatMessage } = useLocales()
  const isMobile = useMaxWidthBreakpoint('s')
  const bannerImg = bannerImgUrl ? (
    <Image width={isMobile ? '100%' : imageWidth} height={isMobile ? imageMobileHeight : imageHeight} src={bannerImgUrl} alt="banner" />
  ) : null
  const labels = (
    <>
      <VStack>
        <Text fontSize="l" fontWeight={600} width="100%" lineHeight="xl">
          {formatMessage(reservationWidgetMessages.resWidgetPrivateEventsBannerLabel)}
        </Text>
        <Text fontWeight={400}>{formatMessage(reservationWidgetMessages.resWidgetPrivateEventsBannerDescription)}</Text>
      </VStack>
    </>
  )
  return (
    <StackResponsive
      justifyContent="space-between"
      alignItems="center"
      data-test="private-events-container"
      width="100%"
      p="m"
      backgroundColor="secondaryBackground"
      borderRadius="s"
      spacing="m"
    >
      {bannerImg ? (
        <StackResponsive spacing="m" mr="m">
          {bannerImg}
          {labels}
        </StackResponsive>
      ) : (
        labels
      )}
      <Flex width={isMobile ? '100%' : buttonWidth} justifyContent="center">
        <Button variant="primary" data-test="button-save-changes" fullWidth onClick={onExploreClick}>
          {formatMessage(reservationWidgetMessages.resWidgetPrivateEventsBannerExploreButtonLabel)}
        </Button>
      </Flex>
    </StackResponsive>
  )
}
