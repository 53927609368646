import { WidgetLanguageService, RTKUtils, type GetWidgetLanguageStringsArgs } from '@sevenrooms/core/api'
import type { WidgetLanguageResult } from '@sevenrooms/core/domain'
import { baseReservationWidgetApi } from './BaseReservationWidgetApi'

export const widgetLanguageApi = baseReservationWidgetApi.injectEndpoints({
  endpoints: builder => ({
    getLanguageStrings: builder.query<WidgetLanguageResult, GetWidgetLanguageStringsArgs>({
      queryFn: RTKUtils.rtkQuery(WidgetLanguageService.getLanguageStrings),
    }),
  }),
})

export const { useGetLanguageStringsQuery } = widgetLanguageApi
